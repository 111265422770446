var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement, html } from 'lit';
import { property } from 'lit/decorators';
import { returnRTEHTML } from '@gbds/core/src/ts/utils/helper-utils';
import { isShiftTab, isTabOnly } from '@gbds/core/src/ts/utils';
import { getFirstAndLastFocusableElement } from '@gbds/core/src/ts/utils/firstlast-focusable-elements';
import { setAttribute, getAttribute } from '@gbds/core/src/ts/utils/attribute-utils';
import { RewardsState } from '../../models/user-info';
import { AuthLiterals } from '../../config/auth-enumerations';
import { lockBodyScroll, unlockBodyScroll } from '../../utils/body-scroll-lock';
import { parseTokenForEmail, parseTokenForName } from '../../utils/auth/verify-token';
import { initUserData } from '../../config/user-info-config';
export class GbpeMyaccountFlyout extends LitElement {
    constructor(backupVehicleImagePath, navConfig) {
        super();
        document.addEventListener('gb-gn-closeMyAccountFlyout', e => this.closeFlyout(e));
        document.addEventListener('gbpe-myAccountFlyout-open', e => this.openFlyout(e));
        document.addEventListener('gb-overlayClosedFromClick', e => this.closeFlyout(e));
        document.addEventListener('keyup', e => this._escapeListenerHandler(e));
        document.addEventListener('gb-updateRewards', e => this.populateRewards(e));
        this._backupVehicleImagePath = backupVehicleImagePath;
        this._urlConfig = navConfig.urls;
    }
    openFlyout(e) {
        lockBodyScroll();
        this.setFlyoutAuthState(e.detail.data.authenticationState);
        this.setFlyoutOpen('true');
        this.populateAuthorData(e.detail.data).then(() => {
            this.setFlyoutHeightStyles(e.detail.data);
        });
    }
    setFlyoutHeightStyles(eventDetail) {
        const vehiclesDisabled = eventDetail.authNavData.disableAuthVehicles;
        const authState = eventDetail.authenticationState;
        if (vehiclesDisabled === 'true' && authState === 'AUTH') {
            this.setFlyoutHeight();
            this.setFlyoutHeightAuto();
        }
    }
    setFlyoutHeight() {
        var _a;
        const myaccFlyoutBody = this.querySelector('myacc-auth-flyout-body');
        const flyoutHeight = (_a = this.querySelector('myacc-header')) === null || _a === void 0 ? void 0 : _a.clientHeight;
        myaccFlyoutBody.style.height = `calc(100% - ${flyoutHeight}px)`;
    }
    setFlyoutHeightAuto(isFlyoutClosed = 'false') {
        const myaccFlyoutBody = this.querySelector('myacc-auth-flyout-body');
        const MyaccFlyoutHeight = this.clientHeight;
        const viewPort = window.innerHeight;
        if (isFlyoutClosed === 'true') {
            this.style.height = '';
            myaccFlyoutBody.style.height = '';
        }
        else {
            this.style.height = viewPort > MyaccFlyoutHeight ? 'auto' : '100%';
        }
    }
    setFlyoutAuthState(state) {
        setAttribute(this, 'flyoutState', state);
    }
    setFlyoutOpen(open) {
        setAttribute(this, 'flyoutOpen', open);
    }
    get isFlyoutOpen() {
        return this.getAttribute('flyoutOpen') === 'true' ? true : false;
    }
    populateAuthorData(eventDetail) {
        return __awaiter(this, void 0, void 0, function* () {
            this._overlay = eventDetail.myaaccountOverlay;
            this._unauthHeaderNode = eventDetail.unauthFlyoutHeaderData;
            this._unauthNavData = eventDetail.unauthNavData;
            this.authNavData = eventDetail.authNavData;
            if (this.flyoutState === AuthLiterals.UNAUTH) {
                this._unauthFooterNode = eventDetail.unauthFlyoutFooterData;
            }
            else {
                this._profile = eventDetail.userData.profile;
                this._rewards = eventDetail.userData.rewards;
                this._rewardsState = eventDetail.rewardsState;
                this._notifications = eventDetail.userData.notification;
                if (this.authNavData.disableAuthVehicles !== 'true') {
                    try {
                        const vehicleData = yield window.gbpe.user.vehicles;
                        this._vehicles = vehicleData.data['garage'];
                        this._vehiclesFallback = false;
                    }
                    catch (e) {
                        console.log('Vehicle API Call Failed!!');
                        this._vehiclesFallback = true;
                    }
                }
                else {
                    return Promise.resolve(true);
                }
            }
        });
    }
    populateRewards(eventDetail) {
        this._rewards = eventDetail.detail.rewards;
        this._rewardsState = eventDetail.detail.rewardsState;
    }
    closeFlyout(event) {
        if (this.isFlyoutOpen) {
            unlockBodyScroll();
            this.setFlyoutOpen('false');
            document.dispatchEvent(new CustomEvent('flyout-close'));
            if (this.authNavData.disableAuthVehicles === 'true' && this.flyoutState === 'AUTH') {
                this.setFlyoutHeightAuto('true');
            }
            if (event.type != 'gb-overlayClosedFromClick') {
                this._overlay.toggleOverlay();
                if (this._parentButton) {
                    this._parentButton.focus();
                    event.preventDefault();
                }
            }
        }
    }
    clearStorage() {
        const userData = initUserData(this._urlConfig);
        sessionStorage.removeItem(userData.profile.storageKey);
        sessionStorage.removeItem(userData.rewards.storageKey);
        sessionStorage.removeItem(userData.vehicles.storageKey);
        sessionStorage.removeItem(userData.missingInfo.storageKey);
    }
    _escapeListenerHandler(event) {
        if (event.key === 'Escape') {
            const openFlyout = getAttribute(this, 'flyoutOpen');
            if (openFlyout === 'true') {
                this.closeFlyout(event);
            }
            event.preventDefault();
        }
    }
    signin() {
        if (this._unauthHeaderNode.signInLink) {
            window.location.assign(this._unauthHeaderNode.signInLink);
        }
        else {
            if (this.flyoutState === AuthLiterals.UNAUTH) {
                this.callAuthentication();
            }
        }
    }
    callAuthentication() {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.gbpe) {
                yield this.renderAuthState();
                window.gbpe.auth.loginRedirect();
            }
        });
    }
    register() {
        if (this.flyoutState === AuthLiterals.UNAUTH) {
            this.callRegister(this._unauthHeaderNode.registerLink);
        }
    }
    callRegister(url) {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.gbpe) {
                yield this.renderAuthState();
                window.gbpe.auth.registerRedirect(url);
            }
        });
    }
    renderAuthState() {
        return __awaiter(this, void 0, void 0, function* () {
            yield window.gbpe.auth.loaded;
        });
    }
    _addButtonListeners() {
        this._parentButton.addEventListener('keydown', e => {
            this._setButtonElements();
            this._handleParentButtonTabEvent(e);
            this._handleParentButtonShiftTabEvent(e);
        });
        if (this._closeButton) {
            this._closeButton.addEventListener('keydown', e => {
                this._addTabListeners();
                this._handleParentButtonShiftTabEvent(e);
            });
        }
    }
    _setButtonElements() {
        this._closeButton = this.querySelector('.stat-button-close');
        this._parentButton = document
            .querySelector('gb-global-nav')
            .shadowRoot.querySelector('gb-myaccount-nav')
            .querySelector('button');
        this._setLastTabbableElements();
        this._addButtonListeners();
    }
    _setLastTabbableElements() {
        if (!this._lastFocusableElement) {
            const { last } = getFirstAndLastFocusableElement(this.shadowRoot || this);
            this._lastFocusableElement = last;
        }
    }
    _addTabListeners() {
        if (!this._isLastFocusableElement(this._closeButton)) {
            this._lastFocusableElement.addEventListener('keydown', e => this._handleLastFocusableElementTabEvent(e));
        }
    }
    _handleParentButtonTabEvent(e) {
        if (isTabOnly(e) && this.isFlyoutOpen) {
            this._closeButton.focus();
            e.preventDefault();
        }
    }
    _handleParentButtonShiftTabEvent(e) {
        if (isShiftTab(e) && this.isFlyoutOpen) {
            this.closeFlyout(e);
            e.preventDefault();
        }
    }
    _handleLastFocusableElementTabEvent(e) {
        if (isTabOnly(e) && this.isFlyoutOpen) {
            this._closeButton.focus();
            e.preventDefault();
        }
    }
    _isLastFocusableElement(el) {
        return el.isSameNode(this._lastFocusableElement);
    }
    closeContent() {
        return html `<myacc-flyout-close>
			<button class="stat-button-close" name="close" @click="${this.closeFlyout}" data-dtm="my account"></button>
		</myacc-flyout-close>`;
    }
    signout() {
        if (window.location.hostname.includes('experience')) {
            document.cookie = 'gm-energy-destroy=true; domain=.gm.com; path=/; max-age=' + 60 * 60 * 24 * 30;
        }
        window.gbpe.auth.logout();
        this.clearStorage();
        document.dispatchEvent(new CustomEvent('signedout'));
    }
    unauthheaderContent() {
        return html `<myacc-header nav-state=${this.flyoutState} 
        enable-unauth-rewards=${this._unauthNavData.enableUnauthRewards}>
            ${this.closeContent()}
			${this._unauthHeaderNode.headingFlyout && this._unauthHeaderNode.headingFlyout !== '' ? this.showHeadingFlyout() : ''}
            <myacc-signin>
                <myacc-signin-img>
                    <img src=${this._unauthHeaderNode.avatarImg} 
                        alt="${this._unauthHeaderNode.avatarImgAltText}"></img>
                </myacc-signin-img>
                <myacc-signin-message>
                    ${returnRTEHTML(this._unauthHeaderNode.signInMsg)}
                </myacc-signin-message>
            </myacc-signin>
            <myacc-signin-button>
                <button class="stat-button-link" name="signin" @click="${this.signin}" data-dtm="my account">${this._unauthHeaderNode.signinButtonLabel}</button>
            </myacc-signin-button>
            ${this.flyoutState === AuthLiterals.UNAUTH && this._unauthNavData.enableUnauthRewards === 'true'
            ? this.registerContent()
            : ''}
        </myacc-header>`;
    }
    showHeadingFlyout() {
        return html `<heading-flyout>${returnRTEHTML(this._unauthHeaderNode.headingFlyout)}</heading-flyout>`;
    }
    registerContent() {
        return html `<myacc-register>
			<myacc-register-text>${returnRTEHTML(this._unauthHeaderNode.registerMsg)}</myacc-register-text>
			<myacc-register-button>
				<button @click="${this.register}" class="stat-button-link" data-dtm="my account">
					${this._unauthHeaderNode.registerButtonLabel}
				</button>
			</myacc-register-button>
		</myacc-register>`;
    }
    unauthfooterContent() {
        return html `<myacc-footer>
            <myacc-placeholder>
                <myacc-placeholder-message>
                    ${returnRTEHTML(this._unauthFooterNode.footerMsg)}
                </myacc-placeholder-message>
                <myacc-placeholder-img>
                    <img src=${this._unauthFooterNode.footerImg} alt="${this._unauthFooterNode.footerImgAltText}"></img>
                </myacc-placeholder-img>
            </myacc-placeholder>
        </myacc-footer>`;
    }
    _getCachedName() {
        const idTokenName = window.gbpe.auth.idToken && window.gbpe.auth.idToken !== ''
            ? parseTokenForName(window.gbpe.auth.idToken)
            : null;
        const idTokenEmail = window.gbpe.auth.idToken && window.gbpe.auth.idToken !== ''
            ? parseTokenForEmail(window.gbpe.auth.idToken)
            : null;
        return idTokenName || idTokenEmail || 'My Account';
    }
    authheaderContent() {
        var _a, _b, _c;
        const _cachedName = this._getCachedName();
        return html `<myacc-header auth enable-auth-rewards=${this._unauthNavData.enableAuthRewards}>
			${this.closeContent()}
			${!((_a = this._profile) === null || _a === void 0 ? void 0 : _a.first_name) && !((_b = this._profile) === null || _b === void 0 ? void 0 : _b.last_name)
            ? html `<myacc-signin>
                    <myacc-signin-img>
                        <img src=${this._unauthHeaderNode.avatarImg} alt="${this._unauthHeaderNode.avatarImgAltText}"></img>
                    </myacc-signin-img>
                    <myacc-signin-message-fallback class="myacc-nav-email">${_cachedName}</myacc-signin-message-fallback>
                </myacc-signin>`
            : html `<myacc-profile-info>
						<myacc-nav-initial data-initial=${this._profile.initial}>
							<myacc-nav-name>${this._profile.first_name}</myacc-nav-name>
							<myacc-nav-email class="myacc-nav-email"
								>${this.authNavData.disableRewardsInNav === 'true'
                ? ''
                : (_c = this._profile) === null || _c === void 0 ? void 0 : _c.login_id}</myacc-nav-email
							>
						</myacc-nav-initial>
				  </myacc-profile-info>`}
			${this._rewards && this._unauthNavData.disableRewardsInFlyout !== 'true' ? this.showRewards() : ''}
		</myacc-header>`;
    }
    showRewards() {
        return html `<myacc-rewards>
			${this._rewardsState === RewardsState.active
            ? this.rewardsContent()
            : this._rewardsState === RewardsState.inactive
                ? this.inactiveRewardsContent()
                : this._rewardsState === RewardsState.closed
                    ? this.closedRewardsContent()
                    : this._rewardsState === RewardsState.other
                        ? this.otherRewardsContent()
                        : ''}</myacc-rewards
		>`;
    }
    inactiveRewardsContent() {
        var _a;
        if ((_a = this.authFlyoutData.inactive) === null || _a === void 0 ? void 0 : _a.inactiveLabel) {
            const inactiveLink = this.authFlyoutData.inactive.inactiveLinkType === 'internal'
                ? this.authFlyoutData.inactive.inactiveInternalLink
                : this.authFlyoutData.inactive.inactiveExternalLink;
            return html `<myacc-enroll>
				<myacc-enroll-text> ${returnRTEHTML(this.authFlyoutData.inactive.inactiveMsg)} </myacc-enroll-text>
				<myacc-enroll-button>
					<a
						target="${this.authFlyoutData.inactive.inactiveLinkTarget}"
						href="${inactiveLink}"
						class="stat-button-link"
					>
						${this.authFlyoutData.inactive.inactiveLabel}
					</a>
				</myacc-enroll-button>
			</myacc-enroll>`;
        }
    }
    closedRewardsContent() {
        var _a;
        if ((_a = this.authFlyoutData.closed) === null || _a === void 0 ? void 0 : _a.closedLabel) {
            const closedLink = this.authFlyoutData.closed.closedLinkType === 'internal'
                ? this.authFlyoutData.closed.closedInternalLink
                : this.authFlyoutData.closed.closedExternalLink;
            return html `<myacc-enroll>
				<myacc-enroll-text> ${returnRTEHTML(this.authFlyoutData.closed.closedMsg)} </myacc-enroll-text>
				<myacc-enroll-button>
					<a
						target="${this.authFlyoutData.closed.closedLinkTarget}"
						href="${closedLink}"
						class="stat-button-link"
					>
						${this.authFlyoutData.closed.closedLabel}
					</a>
				</myacc-enroll-button>
			</myacc-enroll>`;
        }
    }
    otherRewardsContent() {
        var _a;
        if ((_a = this.authFlyoutData.other) === null || _a === void 0 ? void 0 : _a.otherLabel) {
            const otherLink = this.authFlyoutData.other.otherLinkType === 'internal'
                ? this.authFlyoutData.other.otherInternalLink
                : this.authFlyoutData.other.otherExternalLink;
            return html `<myacc-enroll>
				<myacc-enroll-text> ${returnRTEHTML(this.authFlyoutData.other.otherMsg)} </myacc-enroll-text>
				<myacc-enroll-button>
					<a
						target="${this.authFlyoutData.other.otherLinkTarget}"
						href="${otherLink}"
						class="stat-button-link"
					>
						${this.authFlyoutData.other.otherLabel}
					</a>
				</myacc-enroll-button>
			</myacc-enroll>`;
        }
    }
    getTierName() {
        const tierName = this._rewards.tier;
        const tierData = this.authTierData;
        let tierDisplayName;
        Object.keys(tierData).forEach(function (key) {
            if (key.toLowerCase() === (tierName === null || tierName === void 0 ? void 0 : tierName.toLowerCase())) {
                tierDisplayName = returnRTEHTML(tierData[key]);
            }
        });
        return tierDisplayName;
    }
    rewardsContent() {
        var _a;
        const showMinifiedRewardsTxt = this.authNavData.disableRewardsInNav;
        const rewardsLink = this.authFlyoutData.rewards.rewardsLinkType === 'internal'
            ? this.authFlyoutData.rewards.rewardsInternallLink
            : this.authFlyoutData.rewards.rewardsExternalLink;
        const rewardsPts = html `<myacc-rewards-points>
			${this._rewards.points ? this._rewards.points : 0} PTS
		</myacc-rewards-points>`;
        const tierInfoTxt = html `<myacc-rewards-point-uppertext>
			${((_a = this._rewards.tier) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('card'))
            ? html `<myacc-rewards-point>
						${returnRTEHTML(this.authFlyoutData.rewards.rewardsCardLabel)}
				  </myacc-rewards-point>`
            : html `<myacc-rewards-point>
						${returnRTEHTML(this.authFlyoutData.rewards.rewardsLabel)}
				  </myacc-rewards-point>`}
			${this._rewards.tier && this.authTierData
            ? html `<span class="upper-text-seperator">|</span>
						<myacc-rewards-tier> ${this.getTierName()} </myacc-rewards-tier>`
            : ''}
		</myacc-rewards-point-uppertext>`;
        if (showMinifiedRewardsTxt === 'true') {
            return html `<myacc-min-nav-rewards>
				<a
					target="${this.authFlyoutData.rewards.rewardsLinkTarget}"
					href="${rewardsLink}"
					class="stat-button-link"
					data-dtm="my account"
				>
					${rewardsPts} ${tierInfoTxt}
				</a>
			</myacc-min-nav-rewards>`;
        }
        return html `<myacc-nav-rewards>
			<a
				target="${this.authFlyoutData.rewards.rewardsLinkTarget}"
				href="${rewardsLink}"
				class="stat-button-link"
				data-dtm="my account"
			>
				${tierInfoTxt}
				<myacc-rewards-point-lowertext>${rewardsPts}</myacc-rewards-point-lowertext>
			</a>
		</myacc-nav-rewards>`;
    }
    garageContent(vehicleList) {
        return html `<myacc-garage>
			${vehicleList.slice(0, 3).map(link => html `<myacc-garage-vehicle>
                    <a href="${link['link']}" target="_self" class="stat-image-link" data-dtm="my account:my garage">
                        <myacc-garage-thumbnail>
                                <img src="${link['imgUrl']}" alt="${link['title']}" onerror="this.onerror = null; this.dataset.srcError=this.src;this.src='${this._backupVehicleImagePath}'"></img>
                          </myacc-garage-thumbnail>
                          <myacc-garage-vehiclename>
                                ${link['title']}
                          </myacc-garage-vehiclename>
                        </myacc-vehicle-thumbnail>
                        </a>
                    <myacc-garage-vehicle>`)}
			${html `<myacc-viewall-vehicle>
				<myacc-viewall-button>
					<a
						href="${this.authFlyoutData.vehicles.viewAllGarageLink}"
						class="stat-button-link"
						data-dtm="my account:my garage"
						>${this.authFlyoutData.vehicles.viewAllButtonLabel}</a
					>
				</myacc-viewall-button>
			</myacc-viewall-vehicle>`}
		</myacc-garage>`;
    }
    authfooterContent() {
        return html `<myacc-footer>
            <myacc-placeholder>
                <myacc-signout>
                    <button @click="${this.signout}" class="stat-button-link" data-dtm="my account">${this.authFlyoutData.signout.signoutButtonLabel}</button>
                </myacc-placeholder>
            </myacc-placeholder>
        </myacc-footer>`;
    }
    addVehiclesContent() {
        return html `<myacc-add-vehicle>
			<a
				href="${this.authFlyoutData.vehicles.addVehicleLink}"
				target="_self"
				class="stat-image-link"
				data-dtm="my account:my garage"
			>
				<myacc-add-vehicle-img>
					<img
						src="${this.authFlyoutData.vehicles.addVehicleImg}"
						alt="${this.authFlyoutData.vehicles.addVehicleImgAltText}"
					/>
				</myacc-add-vehicle-img>
				<myacc-add-vehicle-label>
					<myacc-add-vehicle-subtext>
						${this.authFlyoutData.vehicles.addVehicleLabel}
					</myacc-add-vehicle-subtext>
				</myacc-add-vehicle-label>
			</a>
		</myacc-add-vehicle>`;
    }
    authlinkContent(position) {
        var _a, _b, _c;
        let linkTemplate = '';
        for (const link in this.authLinkData) {
            const isEnabled = this.authLinkData[link].linkListPosition === position ? true : false;
            const linkDtm = ((_a = this === null || this === void 0 ? void 0 : this.authLinkData[link]) === null || _a === void 0 ? void 0 : _a.dtm1MessageInbox) && ((_b = this === null || this === void 0 ? void 0 : this.authLinkData[link]) === null || _b === void 0 ? void 0 : _b.dtm1MessageInbox) !== ''
                ? (_c = this === null || this === void 0 ? void 0 : this.authLinkData[link]) === null || _c === void 0 ? void 0 : _c.dtm1MessageInbox
                : 'my account:account links';
            const isEnabledInAuth = this.authLinkData[link].authState === 'true' && this.flyoutState === AuthLiterals.AUTH && isEnabled
                ? true
                : false;
            const isEnabledInUnauth = this.authLinkData[link].unauthState === 'true' && this.flyoutState === AuthLiterals.UNAUTH
                ? true
                : false;
            if (isEnabledInAuth || isEnabledInUnauth) {
                linkTemplate =
                    linkTemplate +
                        `<myacc-main-link href=${this.authLinkData[link].link}>
                    <a href=${this.authLinkData[link].link} class="stat-text-link" data-dtm="${linkDtm}">${this.authLinkData[link].linkLabel}</a>
                    ${this.authLinkData[link].subLinkLabel
                            ? `<myacc-sublink>
                            <a href=${this.authLinkData[link].subLink} class="stat-text-link" data-dtm="${linkDtm}">${this.authLinkData[link].subLinkLabel}</a>
                        </myacc-sublink>`
                            : ``}
					${this.flyoutState === AuthLiterals.AUTH && this._notifications
                            ? this.renderMessageIndicator(this.authLinkData[link])
                            : ''}
                </myacc-main-link>`;
            }
        }
        if (this.flyoutState === AuthLiterals.AUTH && position === 'above' && this._vehiclesFallback) {
            const fallbackTemplate = this.fallbackData
                ? `<myacc-main-link>
                        <a href=${this.fallbackData['fallbackLink']} class="stat-text-link" data-dtm="my account:account links">${this.fallbackData['fallbackLinkLabel']}</a>
					</myacc-main-link>`
                : '';
            linkTemplate = linkTemplate + fallbackTemplate;
        }
        return linkTemplate.trim() ? html `<myacc-link> ${returnRTEHTML(linkTemplate)}</myacc-link>` : '';
    }
    renderMessageIndicator(link) {
        return link['msgInboxLink'] === 'true' && this._notifications['notificationOn']
            ? `<span class="myacc-inbox-count">(${this._notifications['unreadCount']})</span><myacc-inbox-notification aria-label="Notification"></myacc-inbox-notification>`
            : '';
    }
    render() {
        var _a;
        if (this.flyoutOpen) {
            return html `${this._setButtonElements()}
			${this.flyoutState === AuthLiterals.AUTH
                ? html `${this.authheaderContent()}
						<myacc-auth-flyout-body>
							${this.authlinkContent('above')}
							${this.authNavData.disableAuthVehicles === 'true'
                    ? ''
                    : !this._vehiclesFallback
                        ? html `${((_a = this._vehicles) === null || _a === void 0 ? void 0 : _a.length) > 0
                            ? this.garageContent(this._vehicles)
                            : this.addVehiclesContent()}`
                        : ''}
							${this.authlinkContent('below')} ${this.authfooterContent()}
						</myacc-auth-flyout-body>
						${this.querySelector('myacc-auth-flyout-body') ? this.setFlyoutHeight() : ''}`
                : html `${this.unauthheaderContent()}
						<myacc-auth-flyout-body>
							${this.authlinkContent()} ${this.unauthfooterContent()}
						</myacc-auth-flyout-body>`}`;
        }
    }
    createRenderRoot() {
        return this;
    }
}
__decorate([
    property({ type: String, reflect: true })
], GbpeMyaccountFlyout.prototype, "flyoutState", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], GbpeMyaccountFlyout.prototype, "flyoutOpen", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "authFlyoutData", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "authNavData", void 0);
__decorate([
    property({ type: Array, reflect: true })
], GbpeMyaccountFlyout.prototype, "authLinkData", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "authTierData", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "fallbackData", void 0);
__decorate([
    property({ type: Object })
], GbpeMyaccountFlyout.prototype, "_profile", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "_rewards", void 0);
__decorate([
    property({ type: Array })
], GbpeMyaccountFlyout.prototype, "_vehicles", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], GbpeMyaccountFlyout.prototype, "_vehiclesFallback", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccountFlyout.prototype, "_notifications", void 0);
