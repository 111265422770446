var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import './myaccount-flyout';
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators';
import { fireAnalyticsEvent } from '@gbds/core/src/ts/utils';
import { setAttribute } from '@gbds/core/src/ts/utils/attribute-utils';
import { RewardsState, UserInfoItems } from '../../models/user-info';
import { Overlay } from '../overlay';
import { AuthLiterals } from '../../config/auth-enumerations';
import { parseTokenForEmail, parseTokenForName } from '../../utils/auth/verify-token';
import { MYACCOUNT_LOADED } from '../../events/rendering';
export class GbpeMyaccount extends LitElement {
    constructor() {
        super();
        this.loadAuthoringContent();
        document.addEventListener('flyout-close', () => this.closeFlyout());
        document.addEventListener('gb-myaccountReset', e => this.refreshUserData(e));
    }
    loadAuthoringContent() {
        return __awaiter(this, void 0, void 0, function* () {
            window.gbpe.auth.loaded.then(() => __awaiter(this, void 0, void 0, function* () {
                this.currentAuthState();
                this.loadOverlay();
                yield this.loadData();
                this._dispatchApiLoaded(MYACCOUNT_LOADED);
            }));
        });
    }
    currentAuthState() {
        if (window.gbpe.auth.currentAuthState === -1) {
            this.state = AuthLiterals.UNAUTH;
        }
        else if (window.gbpe.auth.currentAuthState === 1) {
            this.state = AuthLiterals.AUTH;
        }
    }
    loadOverlay() {
        this._globalNav = document.querySelector('gb-global-nav');
        const misc = this._globalNav.shadowRoot.querySelector('.gn-misc');
        this._overlay = new Overlay(misc);
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state === AuthLiterals.AUTH) {
                yield this.loadUserData();
                this.swapLinks();
            }
        });
    }
    loadUserData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.getProfileData();
            this.getNotificationData();
            this.getCartData();
            this.getKoreaProfileRefresh();
            this.getKoreaCheckJson();
            yield this.getRewardsData();
            if (window.location.href.includes('gmenergy')) {
                if (document.cookie.split('; ').find(row => row.startsWith('gm-energy-destroy='))) {
                    document.cookie = 'gm-energy-destroy=; domain=.gm.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
                    window.gbpe.auth.logout();
                }
            }
        });
    }
    refreshUserData(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const itemName = e.detail.itemName;
            switch (itemName) {
                case UserInfoItems.profile:
                    this.getProfileData();
                    break;
                case UserInfoItems.rewards:
                    this.getRewardsData();
                    break;
                case UserInfoItems.vehicles:
                    this.getVehicleData();
                    break;
                case UserInfoItems.notifications:
                    this.getNotificationData();
                    break;
                case UserInfoItems.cart:
                    this.getCartData();
                case UserInfoItems.koreaProfileRefresh:
                    this.getKoreaProfileRefresh();
                case UserInfoItems.koreaCheckJson:
                    this.getKoreaCheckJson();
            }
        });
    }
    getProfileData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const profileData = yield window.gbpe.user.profile;
                this._profile = profileData.data;
                if (this._profile.status.toUpperCase() === 'SUCCESS' &&
                    this._profile.first_name !== undefined &&
                    this._profile.last_name !== undefined) {
                    if (this.authNavData.myAccName === 'oneInitial') {
                        this._profile.initial = String(this._profile.first_name).charAt(0);
                    }
                    else if (this.authNavData.myAccName === 'firstName') {
                        this._profile.initial = String(this._profile.first_name);
                    }
                    else {
                        this._profile.initial =
                            String(this._profile.first_name).charAt(0) + String(this._profile.last_name).charAt(0);
                    }
                    this._regionRedirect();
                }
                else {
                    this._profile = null;
                    this._profileFallback = true;
                }
            }
            catch (e) {
                console.log('Profile API Call Failed!!');
                this._profileFallback = true;
            }
        });
    }
    swapLinks() {
        window.gbpe.authLinkService.swapLinks();
    }
    _regionRedirect() {
        var _a, _b, _c, _d;
        try {
            const country = (_a = this._profile) === null || _a === void 0 ? void 0 : _a['country'];
            const redirectRequired = country !== undefined && country !== window.gbpe.config.region;
            const language = window.gbpe.config.locale.split('-')[0];
            const countryGBPE = (_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.gbpe) === null || _b === void 0 ? void 0 : _b.config) === null || _c === void 0 ? void 0 : _c.locale) === null || _d === void 0 ? void 0 : _d.split('-')[1];
            console.log(`Logged in User's Country: ${country}\nSite's Detected Country: ${countryGBPE}`);
            let brandsDetourURL = '';
            const detourCountries = ['US', 'KR', 'CA', 'CH', 'SE', 'DE', 'FR', 'GB', 'NL', 'NO', 'LO', 'IT'];
            if (detourCountries.includes(countryGBPE)) {
                if (countryGBPE === 'US') {
                    brandsDetourURL = '/detour';
                }
                else if (countryGBPE === 'CA') {
                    brandsDetourURL = '/en/detour';
                }
                else {
                    brandsDetourURL = `/${countryGBPE}-en/detour`;
                }
            }
            else {
                console.log(`Site's Country Not Configured for Detour\nDetected Site's Country: ${countryGBPE}`);
            }
            if (redirectRequired === true && location.href.includes(brandsDetourURL) !== true) {
                window.location.assign(brandsDetourURL);
            }
        }
        catch (e) {
            console.log('Unable to parse Profile Data for Region Redirect: ', e);
        }
    }
    getRewardsData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.unauthNavData.enableAuthRewards === 'true') {
                    const rewardsData = yield window.gbpe.user.rewards;
                    this._rewards = rewardsData.data['reward'];
                }
                this._rewards ? this.evalRewardsState() : (this._rewardsFallback = true);
                const detail = {
                    rewards: this._rewards,
                    rewardsState: this._rewardsState,
                };
                document.dispatchEvent(new CustomEvent('gb-updateRewards', { detail, bubbles: true }));
                console.log('Rewards state: ' + this._rewardsState);
            }
            catch (e) {
                console.log('Rewards API Call Failed!!');
                this._rewardsFallback = true;
            }
        });
    }
    evalRewardsState() {
        var _a;
        switch ((_a = this._rewards.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) {
            case 'open':
            case 'active':
            case '200':
                this._rewardsState = RewardsState.active;
                break;
            case 'suspended':
            case 'inactive':
                this._rewardsState = RewardsState.inactive;
                break;
            case 'closed':
                this._rewardsState = RewardsState.closed;
                break;
            default:
                if (this._rewards.RespCode === '601') {
                    this._rewardsState = RewardsState.closed;
                }
                else {
                    this._rewardsState = RewardsState.other;
                }
        }
    }
    getVehicleData() {
        return __awaiter(this, void 0, void 0, function* () {
            const vehicleData = yield window.gbpe.user.vehicles;
            this._vehicles = vehicleData.data['garage'];
        });
    }
    getKoreaProfileRefresh() {
        return __awaiter(this, void 0, void 0, function* () {
            const cartData = yield window.gbpe.user.koreaProfileRefresh;
        });
    }
    getKoreaCheckJson() {
        return __awaiter(this, void 0, void 0, function* () {
            const cartData = yield window.gbpe.user.koreaCheckJson;
        });
    }
    getCartData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const shoppingCart = document
                    .querySelector('gb-global-nav')
                    .shadowRoot.querySelectorAll('.gn-main-link .gn-shop-icon')[0]
                    ? true
                    : false;
                if (!shoppingCart)
                    return;
                const cartData = yield window.gbpe.user.cart;
                this._cart = cartData.data.data['cart'];
                if (this._cart) {
                    if (this._cart['order'].itemCount > 0) {
                        document
                            .querySelector('gb-global-nav')
                            .shadowRoot.querySelectorAll('.gn-main-link .gn-shop-icon')[0]
                            .classList.add('gn-cart-full');
                    }
                    else {
                        document
                            .querySelector('gb-global-nav')
                            .shadowRoot.querySelectorAll('.gn-main-link .gn-shop-icon')[0]
                            .classList.remove('gn-cart-full');
                    }
                }
            }
            catch (e) {
                console.error('Shopping Cart API call failed, ', e);
            }
        });
    }
    getNotificationData() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            try {
                const notificationData = yield window.gbpe.user.notifications;
                this._notifications = {
                    notificationOn: ((_a = notificationData.data) === null || _a === void 0 ? void 0 : _a.unreadCount) && ((_b = notificationData.data) === null || _b === void 0 ? void 0 : _b.unreadCount) > 0,
                    unreadCount: notificationData.data.unreadCount,
                };
            }
            catch (e) {
                console.error('Notification API call failed, ', e);
                this._notifications = {
                    notificationOn: false,
                    unreadCount: 0,
                };
            }
        });
    }
    openFlyout() {
        setAttribute(this, 'flyout', 'open');
        this.setGlobalNavProperties();
        const authUserData = {
            profile: this._profile,
            rewards: this._rewards,
            vehicles: this._vehicles,
            missingInfo: this._missingInfo,
            notification: this._notifications,
        };
        const data = {
            authenticationState: this.state,
            unauthNavData: this.unauthNavData,
            unauthFlyoutHeaderData: this.unauthFlyoutData['header'],
            unauthFlyoutFooterData: this.unauthFlyoutData['footer'],
            myaaccountOverlay: this._overlay,
            userData: authUserData,
            rewardsState: this._rewardsState,
            authNavData: this.authNavData,
        };
        fireAnalyticsEvent('gbpe', 'myAccountFlyout', 'open', 'interaction', data);
    }
    setGlobalNavProperties() {
        this._overlay.setZHeight();
        this._overlay.closeOverlayOnClick = true;
        this._overlay.toggleOverlay();
    }
    closeFlyout() {
        setAttribute(this, 'flyout', 'close');
    }
    _getCachedName() {
        const idTokenName = window.gbpe.auth.idToken && window.gbpe.auth.idToken !== ''
            ? parseTokenForName(window.gbpe.auth.idToken)
            : null;
        const idTokenEmail = window.gbpe.auth.idToken && window.gbpe.auth.idToken !== ''
            ? parseTokenForEmail(window.gbpe.auth.idToken)
            : null;
        return idTokenName || idTokenEmail || 'My Account';
    }
    _dispatchApiLoaded(name) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            yield ((_b = (_a = this._globalNav) === null || _a === void 0 ? void 0 : _a.flyoutService) === null || _b === void 0 ? void 0 : _b.allFlyoutsLoaded);
            window.dispatchEvent(new CustomEvent(name));
        });
    }
    authNav() {
        var _a;
        const _cachedName = this._getCachedName();
        return html `${!this._profileFallback && this._profile !== undefined
            ? html `<myacc-navigation-header auth data-initial=${this._profile.initial}>
						${this.authNavData.disableRewardsInNav === 'true'
                ? ''
                : html `<myacc-nav-name>${this._profile.first_name}</myacc-nav-name>`}
						<myacc-nav-rewards>
							<span class="lower-text">
								${this._rewardsState === RewardsState.active &&
                this._rewards.points &&
                this.authNavData.disableRewardsInNav !== 'true'
                ? this._rewards.points + ' PTS'
                : ''}
							</span>
						</myacc-nav-rewards>
						${this.authNavData['msgInboxLink'] === 'true' && ((_a = this._notifications) === null || _a === void 0 ? void 0 : _a['notificationOn'])
                ? html `<myacc-inbox-notification aria-label="Notification"></myacc-inbox-notification>`
                : ''}
				  </myacc-navigation-header>`
            : html `<myacc-navigation-header unauth fallback>
				<myacc-nav-image><img src=${this.unauthNavData.avatarImg} alt="${this.unauthNavData.avatarImgAltText}"></img></myacc-nav-image>
				<myacc-nav-fallback>
					<myacc-nav-name>${_cachedName}</myacc-nav-name>
					<myacc-nav-rewards>
						<span class="lower-text">${this._rewardsState === RewardsState.active && this._rewards.points
                ? this._rewards.points + ' PTS'
                : ''}</span>
					</myacc-nav-rewards>
				</myacc-nav-fallback>`}
		</myacc-navigation-header>`;
    }
    unauthNav() {
        return html `<myacc-navigation-header unauth>
			<myacc-nav-image><img src=${this.unauthNavData.avatarImg} alt="${this.unauthNavData.avatarImgAltText}"></img></myacc-nav-image>
			<myacc-nav-text><myacc-button class="gf-main-button">${this.unauthNavData.avatarLabel}</myacc-button></myacc-nav-text>
			</myacc-navigation-header>`;
    }
    navOnClick() {
        switch (this.state) {
            case AuthLiterals.UNAUTH:
                if (this.unauthNavData.disableUnauthFlyout === 'true') {
                    window.gbpe.auth.loginRedirect();
                }
                else {
                    this.openFlyout();
                }
                break;
            case AuthLiterals.AUTH:
                if (this.authNavData.disableAuthFlyout === 'true') {
                    this.redirectToAuthoredLink();
                }
                else {
                    this.openFlyout();
                }
                break;
        }
    }
    redirectToAuthoredLink() {
        window.location.href = this.authNavData.disableFlyoutRedirect;
    }
    render() {
        return html `
			<button class="gf-main-button stat-text-link" @click="${this.navOnClick}" data-dtm="global nav">
				${this.state === AuthLiterals.AUTH ? this.authNav() : this.unauthNav()}
			</myacc-navigation-header>
			</button>`;
    }
    createRenderRoot() {
        return this;
    }
}
__decorate([
    property()
], GbpeMyaccount.prototype, "flyout", void 0);
__decorate([
    property({ type: String, reflect: true })
], GbpeMyaccount.prototype, "state", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccount.prototype, "unauthNavData", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccount.prototype, "authNavData", void 0);
__decorate([
    property({ reflect: true, type: Object })
], GbpeMyaccount.prototype, "unauthFlyoutData", void 0);
__decorate([
    property({ type: Object })
], GbpeMyaccount.prototype, "_rewards", void 0);
__decorate([
    property({ type: Array })
], GbpeMyaccount.prototype, "_vehicles", void 0);
__decorate([
    property({ type: Object })
], GbpeMyaccount.prototype, "_profile", void 0);
__decorate([
    property({ type: Object })
], GbpeMyaccount.prototype, "_cart", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], GbpeMyaccount.prototype, "_profileFallback", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], GbpeMyaccount.prototype, "_rewardsFallback", void 0);
__decorate([
    property({ type: Object, reflect: true })
], GbpeMyaccount.prototype, "_notifications", void 0);
