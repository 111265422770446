var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UserInformation } from '../user-info';
import { KoreaPortableAPIV2 } from './korea-portable-api-v2';
import { initUserData } from '../../../../config/user-info-config';
let apiInstance;
export class KoreaUserInformationV2 extends UserInformation {
    constructor(urlConfig) {
        super(urlConfig);
        this._userData = initUserData(urlConfig);
    }
    _getPortableApi(name, dependency) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            apiData.dependentInstance = dependency;
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new KoreaPortableAPIV2(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    get rewards() {
        return Promise.reject('Rewards not supported by Korea Configuration');
    }
    get notifications() {
        return Promise.reject('Notification not supported by Korea Configuration');
    }
}
