import { PortableAPI } from '../portable-api';
import { LEGACY_KOREA_APP_PROFILE_REFRESH_PATH } from '../../../../config/paths';
export class KoreaPortableAPIV2 extends PortableAPI {
    reset() {
        super.reset();
        const error = new Error(`Korea Profile Refresh Call Failed (Within Reset)`);
        const failedMessage = 'ERROR: Failed to refresh the profile information on the Korea Server.';
        console.log('Korea Profile Refresh initiated by reset request');
        super.retryAPICall(LEGACY_KOREA_APP_PROFILE_REFRESH_PATH, 2, 5000, error, failedMessage).catch(err => {
            throw err;
        });
    }
}
